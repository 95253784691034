import me from "../assets/images/me.png";
import fumika from "../assets/images/fumika.png";

type prop = {
  pervMode: boolean;
};

function Skills({ pervMode }: prop) {
  return (
    <div className="p-2">
      <p className="text-3xl text-center">Areas of Knowledge</p>
      <div className="flex flex-col md:flex-row justify-center align-around gap-3">
        <div className="border border-stone-300 basis-1/3 p-4 ">
          {pervMode ? (
            <>
              <br></br>
              <p className="text-center">JAV</p>
              <hr></hr>
              <p>
                うおおおお、熟女のおまんこ中だしたい。うおおおおおひぐううううう。
              </p>
              <br></br>
              <p>
                I literally studied Japanese in college to better enjoy their
                porn.
              </p>
            </>
          ) : (
            <>
              <p className="text-center">Web Development</p>
              <hr></hr>
              <p>Web apps, Landing pages, and APIs</p>
              <br></br>
              <p>
                I don't like toy projects, and if possible, I always want to be
                making something someone will use.
              </p>
            </>
          )}
        </div>
        <div className="border border-stone-300 basis-1/3 p-4">
          {pervMode ? (
            <>
              <p className="text-center">Cumming</p>
              <hr></hr>
              <img className="me max-w-[75%] m-auto" src={me} alt=""></img>
            </>
          ) : (
            <>
              <p className="text-center">Japanese</p>
              <hr></hr>
              <p>こんばんは、マーシュと申します。</p>
              <br></br>
              <p>
                I studied a bit of Japanese in college. I'm still not the
                greatest but kanji is a lifelong mess.
              </p>
            </>
          )}
        </div>
        <div className="border border-stone-300 basis-1/3 p-4 relative">
          {pervMode ? (
            <>
              <img
                className="absolute top-0 left-0 w-auto m-auto h-[100%] opacity-10"
                src={fumika}
                alt=""
              ></img>
            </>
          ) : null}
          {pervMode ? (
            <>
              <p className="text-center">Hentai</p>
              <hr></hr>
              <p>中だし、おっぱい、おまんこ</p>
            </>
          ) : (
            <>
              <p className="text-center">Data Science / Statistics</p>
              <hr></hr>
              <p>Data scraping, cleaning, and analzying</p>
              <br></br>
              <p>An interesting area with a lot of tasks that vary greatly.</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Skills;
