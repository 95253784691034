import {FaTwitter} from 'react-icons/fa';
import {IoMail} from 'react-icons/io5';


function Footer() {
    return (
        <div className="bg-black text-white p-4 mt-3 flex justify-center content-center">
            <div className="flex flex-col justify-center content-center w-fit">
                <p className="text-center">Contact</p>
                <div className='flex content-center'>
                    <IoMail/>
                    <a href="mailto:marchemurase@gmail.com">marchemurase@gmail.com</a>
                </div>
                <div className='flex'>
                    <FaTwitter/>
                    <a href='https://twitter.com/nsfw_developer'>@nsfw_developer</a>  
                </div>
            </div>
        </div>
    )   
}

export default Footer;