import aiHen from "../assets/images/aihen.jpg";
import chad from "../assets/images/gigachad.jpg";

type prop = {
  pervMode: boolean;
};

function Project({ pervMode }: prop) {
  return (
    <div className="p-2 flex flex-center justify-center min-h-[75vh]">
      <div className="flex flex-center justify-center w-full md:w-3/4 md:m-5">
        <div className="basis-3/4 border border-stone-300 p-3 break-normal relative">
          {pervMode ? (
            <>
              <img
                className="absolute top-0 left-0 w-[100%] h-[100%] opacity-10"
                src={chad}
                alt=""
              ></img>
            </>
          ) : null}
          <p className="text-center text-3xl">
            Current Main Project: JAV Recommendation System
          </p>
          <hr></hr>
          <br></br>
          <p>
            There is so much JAV in the world, right? Well... Right now I am
            working on a project that will make searching for JAV I like easier.
            In order to do that, I am working on a recommendation system.
          </p>
          <br></br>
          <p>Process:</p>
          <ol className="list-decimal list-inside">
            <li>
              Create data scrapers for all the main JAV providers / compilers
              and put them into a database
            </li>
            <li>
              Create a system that will help me clean and manage the database
            </li>
            <li>Create the JAV recommendation system site</li>
            <li>Implement content-based filtering</li>
            <li>Gather users</li>
            <li>Add collaboration-based filtering</li>
          </ol>
        </div>
        <div
          className="basis-1/4"
          style={{
            backgroundImage: `url(${
              pervMode ? aiHen : "https://i.imgur.com/BGiuVMX.jpg"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "cover",
          }}
        ></div>
      </div>
    </div>
  );
}

export default Project;
