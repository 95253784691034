import { useEffect, useState } from 'react';
import bgImg from '../assets/images/yuno.jpg';

type prop = {
    pervMode: boolean
}

function HeroBanner({pervMode} : prop) {
    const [scareTimer, setScareTimer] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if(scareTimer > 0 && scareTimer % 41 === 0) {
                setScareTimer(1);
            }
            else {
                setScareTimer(prevTimer => prevTimer + 1);
            }
        }, 100);
        return () => clearInterval(interval);
    })

    return (
        <div className={`relative text-center h-screen flex flex-col justify-center align-center p-2 text-white ${pervMode === true ? 'coomer-breathe' : ''}`} style={{'backgroundImage': `url(${bgImg})`, 'backgroundPosition':'center', 'backgroundAttachment':'fixed'}}>
            {
                pervMode === false ?
                <div className={`img-opacity absolute ${scareTimer % 40 === 0 ? '' : 'bg-black/[.97]' } -z-0 w-full h-full top-0 left-0`}></div>
                :
                null
            }
            <h1 className="text-5xl z-50">Marche</h1>
            <br></br>
            <h2 className="text-2xl z-50">{pervMode ? 'Braindead Coomer' : 'Programmer'}</h2>
        </div>
    )
}

export default HeroBanner;