import eyeCatchPro from "../assets/images/hanekawa.jpg";
import eyeCatchHen from "../assets/images/henkuma1.jpg";

type prop = {
  pervMode: boolean;
};

function Summary({ pervMode }: prop) {
  return (
    <div className="p-2 flex flex-center justify-center min-h-[75vh]">
      <div className="flex flex-center justify-center w-full md:w-3/4 md:m-5 ">
        <div
          className="basis-1/4"
          style={{
            backgroundImage: `url(${pervMode ? eyeCatchHen : eyeCatchPro})`,
            backgroundSize: "cover",
            backgroundPosition: "cover",
          }}
        ></div>
        <div className="basis-3/4 border border-stone-300 p-3 break-normal">
          <p className="text-3xl text-center">About</p>
          <br></br>
          {pervMode ? (
            <>
              <p>Hey there, horny braindead mc-coomer here.</p>
              <br></br>
              <p>
                The world is fucked and people are awful. Since we're all going
                to die anyway, let's have as much fun as possible.
              </p>
              <br></br>
              <p>Interests: Cumming until I pass out, exploring porn</p>
              <br></br>
              <p>I have 2 goals:</p>
              <p>1. Coom as many times as I can.</p>
              <p>2. Help people coom as many times as they can.</p>
            </>
          ) : (
            <>
              <p>Hey there, my name is Marche.</p>
              <br></br>
              <p>I enjoy researching and discovering porn.</p>
              <br></br>
              <p>
                Interests: Data collection / hoarding, making tools, analyzing
                data and writing reports
              </p>
              <br></br>
              <p>I have 2 goals:</p>
              <p>
                1. Explore the world of porn through data science and
                statistics.
              </p>
              <p>
                2. Release my tools and findings to the world via webdev and
                articles.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Summary;
