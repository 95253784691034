import React, { useState } from "react";
import "./App.css";
import Modal from "./components/Modal";
import SiteToggle from "./components/SiteToggle";
import HeroBanner from "./components/HeroBanner";
import Summary from "./components/Summary";
import Skills from "./components/Skills";
import Project from "./components/Project";
import Blog from "./components/Blog";
import Call from "./components/Call";
import Footer from "./components/Footer";
import bgImg from "./assets/images/yuno.jpg";

function App() {
  const [pervMode, setPervMode] = useState(false);

  return (
    <div className={`app `}>
      {pervMode === true ? (
        <div
          className="fixed w-screen h-screen bg-rose-300/[.30] top-0 left-0 opacity-10"
          style={{
            pointerEvents: "none",
            backgroundImage: `url(${bgImg})`,
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
          }}
        ></div>
      ) : null}
      {pervMode === true ? (
        <div
          className="fixed w-screen h-screen bg-rose-300/[.30] top-0 left-0"
          style={{ pointerEvents: "none" }}
        ></div>
      ) : null}
      <SiteToggle pervMode={pervMode} setPervMode={setPervMode} />
      <HeroBanner pervMode={pervMode} />
      <Summary pervMode={pervMode} />
      <Skills pervMode={pervMode} />
      <Project pervMode={pervMode} />
      <Blog />
      <Call pervMode={pervMode} />
      <Footer />
    </div>
  );
}

export default App;
