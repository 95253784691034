
type prop = {
    pervMode: boolean
}

function Call({pervMode} : prop) {
    return (
        <div className="bg-emerald-100 w-3/4 m-auto p-3 rounded-lg text-center">
            {
                pervMode ?
                <p>Have an idea that will drain coomers of their money but don't know how to build it? Let's make it happen.</p>
                :
                <p>Are you looking to make an adult website or application? I'm available for work.</p>
            }
        </div>
    )
}

export default Call;