import { useEffect, useState } from "react";

interface Post {
    _id: string,
    title: string,
    url: string,
    thumbnail: string
}


function Blog() {
    const [posts, setPosts] = useState<Post[]>([]);
    useEffect(() => {
        let url ='https://floating-wave-75968.herokuapp.com/posts/1'
        fetch(url, {
            method:'GET'
        }).then(res => {
            if(res.status === 200 || res.status === 304) {
                return res.json()
            }
        }).then(res => {
            setPosts(res.posts.slice(0,3));
        })
    }, []);

    return(
        <div className="p-2">
            <p className="text-3xl text-center">Latest Blog Posts</p>
            <div className="flex flex-col  md:flex-row p-4 justify-center gap-3 align-center">
                {posts.length > 0 ? 
                posts.map((value, index) => {
                    return (
                        <div key={value._id} className={'text-center border border-stone-300 basis-1/3 p-2'}>
                        <a href={'https://blog.fapalytics.com/post/' + value._id} target="_blank" rel="noreferrer noopener">{value.title}</a>
                        <a href={'https://blog.fapalytics.com/post/' + value._id} target="_blank" rel="noreferrer noopener">
                            <img className='h-auto w-full' src={value.thumbnail} alt=''></img>
                        </a>
                        </div>
                    )
                })
            :
            <p>loading...</p>}
            </div>
        </div>
    )
}

export default Blog;