import { Dispatch, SetStateAction } from 'react';
import mememeBtn from '../assets/gifs/mememe-button.gif';
import mememeDance from '../assets/gifs/mememe-dance.gif';

type myProps = {
    pervMode: boolean,
    setPervMode: Dispatch<SetStateAction<boolean>>;
}

function SiteToggle({pervMode, setPervMode} : myProps) {
    return (
        <div className='fixed bottom-0 right-0 z-50'>
            {
                pervMode ? 
                <p className='text-center bg-white p-1 rounded m-2 border border-stone-300 '>???</p>
                :
                <p className='text-center bg-white p-1 rounded m-2 border border-stone-300 '>Click me &#9829;</p>
            }
            <img onClick={() => setPervMode(previousMode => !previousMode)} 
            className='cursor-pointer max-h-[112px]' 
            src={pervMode === false ? mememeBtn : mememeDance} alt='click me'></img>
        </div>
    )
}

export default SiteToggle;